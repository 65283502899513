import { BrowserRouter, Route, Routes } from "react-router-dom";


import { MainRoutes } from "./routes/MainRoutes";
import LoginView from "./views/auth/LoginView";
import ForgetPasswordView from "./views/auth/ForgetPasswordView";
import ResetPassword from "./views/auth/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route index element={<LoginView />} />
        <Route path="mot-de-passe-oublie" element={<ForgetPasswordView />} />
        <Route path="password-reset-confirm/:uid/:token/" element={<ResetPassword />} />
        <Route path="*" element={<MainRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
