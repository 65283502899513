import * as Icons from "react-feather";

export default function ErrorMessages(props) {
  // console.log(props);
  if (props.children) return;
let mgs = "Désolé nous avons une erreur veuillez réessayer s'il vous plaît"
  const AlertIcon = <Icons.AlertOctagon className="w-6 h-6 mr-2" />;

  return (
    <>
      {props.children instanceof Array ? (
        props.children.map((item, index) => {
          return (
            <div
              className="rounded-md flex items-center px-5 py-4 
                        mb-2 bg-theme-31 text-theme-6"
              key={index}
            >
              {AlertIcon} {item}
            </div>
          );
        })
      ) : (
        <div
          className="rounded-md flex items-center px-5 py-4 mb-2 
                bg-theme-31 text-theme-6"
        >
          {AlertIcon} {mgs}
        </div>
      )}
    </>
  );
}
