// import { Data } from "../data";

const parseJson = (value) => {
  if (!value || value === "") return {};
  return JSON.parse(value);
};

const isJsonParseable = (value) => {
  try {
    Object.keys(JSON.parse(value));
    return true;
  } catch (error) {
    return false;
  }
};

const parseBool = (string) => {
  const val = string.toLowerCase();

  if (val === "oui") return "true";
  if (val === "non") return "false";

  return string;
};

const stringifyBool = (anyValue) => {
  if (anyValue.toString() === "true") return "Oui";
  if (anyValue.toString() === "false") return "Non";

  return anyValue;
};

const parseFloat = (string) => {
  // if (typeof string === "string" && string.toLowerCase().includes('fcfa'))
  //     return parseFloat(string.toLowerCase().replace(/\s/g, '')
  //     .replace('fcfa', ''));

  return string;
};

const parseArray = (value) => {
  if (typeof value === "object" && value instanceof Array)
    return value.join(" - ");

  return value;
};

// const __ = (key) => {
//   const value = Data.Dictionnaries.DemandeLabels[key];

//   if (!value || value === undefined || value === "") return key;
//   return value;
// };

const stringLength = (text) => {
  const lenghtText = 80;
  return text > lenghtText
    ? text.substring(0, lenghtText) + "..."
    : text;
};

export const String = {
  // __,
  parseJson,
  parseBool,
  stringifyBool,
  parseFloat,
  parseArray,
  isJsonParseable,
  stringLength,
};
