import { useCallback, useEffect, useMemo, useState } from "react";
// import { Link } from "react-router-dom";
import { Api } from "../services/Api";
import styleDate from "../utils/Date";
// import { String } from "../utils/String";
import Skeleton from "react-loading-skeleton";
import * as Icons from "react-feather";
import { nextPage, previousPage } from "../utils/pagination";

const Client = () => {
  const abortController = useMemo(() => new AbortController(), []);
  const [dataUsers, setdataUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const ELEMENET_PER_PAGE = 20;

  const listUsers = useCallback(async () => {
    const results = await Api.get("recouv/users/", abortController.signal);
    // console.log("listUsers", results);
    if (results) {
      setLoading(false);
    }

    setdataUsers(results);

    const PAGE_NUMBER = Math.ceil(results.count / ELEMENET_PER_PAGE);

    setTotalPage(PAGE_NUMBER);
  }, [abortController]);

  const handleNextPage = async (params) => {
    setLoading(true);
    if (params) {
      const datas = await nextPage(params);
      setPage(page + 1);
      setdataUsers(datas);
      // dataHistory.next === null ? setIsDisabled(true) : setIsDisabled(false);
    }
    setLoading(false);
  };

  const handlePreviousPage = async (params) => {
    setLoading(true);
    if (params) {
      const datas = await previousPage(params);
      page > 0 ? setPage(page - 1) : setPage(1);
      setdataUsers(datas);
      // dataHistory.previous === null
      //   ? setIsDisabled(true)
      //   : setIsDisabled(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    listUsers();
  }, [listUsers]);
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 mt-6">
        <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          {loading ? (
            <Skeleton count={20} />
          ) : dataUsers ? (
            <>
              <table className="table table-report sm:mt-2">
                <thead className=" text-gray-800">
                  <tr className="">
                    <th className="whitespace-no-wrap">NOM & PRENOM</th>
                    <th className="whitespace-no-wrap">CONTACTS</th>
                    <th className="text-center whitespace-no-wrap">MAIL</th>
                    <th className="text-center whitespace-no-wrap">
                      DATE D'INSCRIPTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataUsers.results.map((item, index) => {
                    return (
                      <tr
                        className="intro-x"
                        key={index}
                        data-testid ="liste-client"
                      >
                        <td className="text-start">
                          <div className="  text-gray-800">
                            {item.username} 
                          </div>
                        </td>
                        <td className="">
                          <div className=" text-gray-800">
                            {item.phone
                              ? item.phone
                              : "Aucun numero enregistré"}
                          </div>
                        </td>
                        <td className=" text-center">
                          <div className="text-gray-800">{item.email}</div>
                        </td>
                        <td className=" ">
                          <div className="flex justify-center items-center text-gray-800">
                            {styleDate(item.created_at)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                className={
                  dataUsers.count > 20
                    ? "px-5 py-3 flex justify-end text-right border-t border-gray-200 "
                    : "hidden"
                }
              >
                <button
                  onClick={() => handlePreviousPage(dataUsers.previous)}
                  type="button"
                  className="button  flex w-36 items-center  border bg-theme-1 text-white "
                >
                  <span>
                    <Icons.ArrowLeft />
                  </span>
                  <span> Precedent </span>
                </button>
                <div className="bg-white text-gray-600 bold mx-5 p-3 box ">
                  {dataUsers.results ? (
                    <span>
                      {page} / {totalPage}
                    </span>
                  ) : (
                    0
                  )}
                </div>
                <button
                  onClick={() => handleNextPage(dataUsers.next)}
                  type="button"
                  className="button  flex w-36 items-center  border bg-theme-1 text-white"
                >
                  <span> Suivant </span>
                  <span>
                    <Icons.ArrowRight />
                  </span>
                </button>
              </div>
            </>
          ) : (
            <div className="intro-y bg-white p-5  text-center text-3xl text-gray-600 mt-3">
              Désolé pas d'information disponible
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Client;
