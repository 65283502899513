const getSessionToken = () => {
    return window.window.localStorage.getItem('utkd');
}

const isLoggedIn = () => {
    if (getSessionToken() === '' || !getSessionToken())
        return false;

    return true;
}

const setSessionToken = token => {
    window.localStorage.setItem('utkd', token)
}

const setUser = user => {
    window.localStorage.setItem('userd', JSON.stringify(user))
}

const removeSessionToken = () => {
    window.localStorage.removeItem('utkd');
    window.localStorage.removeItem('userd');
}

const redirectIfSessionExpired = (err, history) => {
    if (!err) return;
    
    if (err.status && err.status === 'Token is Expired') {
        removeSessionToken();
        history.push('/');
    }
}

const getUser = () => {
    return {
        ...JSON.parse(window.localStorage.getItem('userd'))
    }
}

export const Auth = {
    isLoggedIn,
    getSessionToken,
    setSessionToken,
    removeSessionToken,
    redirectIfSessionExpired,
    getUser,
    setUser
}
