import { Link, NavLink, useLocation } from 'react-router-dom';
import logoImg from '../assets/images/Logo_DATA_GREFFE.png';
import * as Icons from 'react-feather';
import { Utils } from '../utils';
import { Auth } from '../utils/Auth';

export function MobileMenu(){
    const {pathname} = useLocation();
    const {role} = Auth.getUser();
    const activeClassName = 'menu--active';
    const dynamicClassName = ({ isActive }) => {;
        return isActive ? `menu ${activeClassName}` : 'menu';
    }

    return (
        <div className="mobile-menu md:hidden">
            <div className="mobile-menu-bar pt-5">
                <Link to="/" className="flex mr-auto">
                    <img alt="Midone Tailwind HTML Admin Template" className="w-16" src={logoImg} />
                </Link>
                <button className='btn btn-link rounded' id="mobile-menu-toggler"> 
                    <Icons.BarChart2 className='w-8 h-8 text-white transform -rotate-90' 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}/>
                 </button>
            </div>
            <ul className="border-t bg-theme-11 py-5 hidden" id="mobile-menu-dropdown">
                <li className={role ==="administrateur_TCA"? 'hidden':''}>
                    <Link to="/Client" className={`menu ${pathname === '/Client' ? activeClassName : ''}`} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.Users /> </div>
                        <div className="menu__title"> Clients </div>
                    </Link>
                </li>
                <li>
                    <NavLink to="/Achat" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.ShoppingCart /> </div>
                        <div className="menu__title"> Achat </div>
                    </NavLink>
                </li>
                <li className={role ==="administrateur_TCA"? 'hidden':''}>
                    <NavLink to="/Recherche" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.Search /> </div>
                        <div className="menu__title"> Recherche </div>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/Aides" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.HelpCircle /> </div>
                        <div className="menu__title"> Aides </div>
                    </NavLink>
                </li> */}
                
            </ul>
        </div>
    )
}