import { Utils } from ".";

let nextPage = async (next) => {
  return await fetch(`${next}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => data);
};

let previousPage = async (previous) => {
  return await fetch(`${previous}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => data);
};

export { nextPage, previousPage };
