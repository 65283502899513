import { Auth } from './Auth';
import { Dom } from './Dom'
import styleDate from './Date';
// import { String } from './String';

export const Utils = {
    Auth,
	Dom,
    styleDate,
    // String
}