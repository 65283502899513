import { Utils } from "../utils";
import { Api } from "./Api";

const URL = process.env.REACT_APP_HOST;

// const HEADERS = new Headers({
//   "Content-type": "application/json",
//   Accept: "application/json",
//   Connection: "keep-alive",
//   Authorization: `Bearer ${Utils.Auth.getSessionToken()}`,
// });

const ENPOINTS = {
  Login: "recouv/api-token-auth",
  Logout: "recouv/logout",
  Register: "register",
  Password: "recouv/password-reset-confirm",
  Mail: "recouv/password-reset",
};

/**
 *
 * @param {object} payload
 * @param {AbortSignal} signal
 * @returns {Promise}
 */

const login = (payload, signal) => {
  // return Api.post(ENPOINTS.Login, payload, signal)

  return new Promise((resolve, reject) => {
    fetch(`${URL}/${ENPOINTS.Login}`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Connection: "keep-alive",
      },
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const register = (payload, signal) => {
  return Api.post(ENPOINTS.Register, payload, signal);
};

/**
 *
 * @param {object} payload
 * @param {AbortSignal} signal
 */

const password = (payload, signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${ENPOINTS.Password}`, {
      method: "post",
      body: payload,
      headers: {
        "Content-type": "application/json",
      },
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

const mail = (payload, signal) => {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${ENPOINTS.Mail}`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: payload,
      signal,
    })
      .then((response) => {
        if (!response.ok) {
          return reject({
            status: response.status,
          });
        }

        return response.json();
      })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
};

/**
 *
 * @param {path} navigate
 */
const logout = (navigate) => {
  Utils.Auth.removeSessionToken();

  window.location.replace("/");
};

export const AuthService = {
  login,
  logout,
  register,
  password,
  mail,
};
