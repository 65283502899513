import  { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../assets/images/Logo_DATA_GREFFE.png";
import { AuthService } from "../../services/AuthService";
import { Utils } from "../../utils";
import ErrorMessages from "../../components/ErrorMessages";
// import Loader from "../../components/Loader";
import * as Icons from "react-feather";

const LoginView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const abortController = new AbortController();
  const navigate = useNavigate();
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setErrorMessages([]);
    setIsDisabled(true);
    try {
      const payload = {
        email,
        password,
      };
      const { user, access_token } = await AuthService.login(
        JSON.stringify(payload),
        abortController.signal
      );

      // console.log("user", user);
      if (access_token && user.role ==="administrateur_TCA") {
        window.location.replace("/Achat");

      }else{

        window.location.replace("/Client");
      }

      Utils.Auth.setSessionToken(access_token);
      Utils.Auth.setUser(user);
    } catch (error) {
      setErrorMessages();
    }
    setIsDisabled(false);
  };
  const onShowPassword = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (!Utils.Auth.isLoggedIn()) navigate("/");
  }, [navigate]);
  return (
    <form name="loginForm" id="loginForm" onSubmit={handleLoginSubmit}>
      <div className="login">
        <div className="container">
          <div className="block xl:grid grid-cols-2 gap-4">
            <div className="hidden xl:flex flex-col min-h-screen">
              <div className="my-auto px-auto">
                <Link to="" className="-intro-x flex mx-20 items-center">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    className="w-80 h-64"
                    src={logoImg}
                  />
                </Link>
                <div className="-intro-x text-white font-medium text-xl leading-tight  dark:text-gray-500">
                  Accédez a toutes les informations légales,
                  <br /> juridiques et financières sur les entreprises de votre
                  choix.
                </div>
                <div className="-intro-x mt-8 text-md text-white dark:text-gray-500">
                  Gérez tous vos documents achetés en un seul endroit.
                </div>
              </div>
            </div>

            <div className="h-screen xl:h-auto flex">
              <div className="my-auto mx-auto  bg-white xl:bg-transparent  px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <ErrorMessages>{errorMessages}</ErrorMessages>
                <h2 className="intro-x font-bold  text-theme-11 text-2xl xl:text-3xl text-center ">
                  Connectez-vous !
                </h2>

                <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">
                  Gérez tous vos documents achetés en un seul endroit.
                </div>
                <div className="intro-x mt-8">
                <div className="flex items-center justify-between">
                  <input
                    type="text"
                    className="intro-x w-full xl:w-full input text-gray-700 border border-gray-300 block"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isDisabled}
                    required
                    autoComplete="on"
                  />
                  <div>ddsds</div>
                  </div>
                  <div className="flex items-center justify-between">
                    <input
                      type={show ? "text" : "password"}
                      placeholder="Password"
                      className="intro-x w-full xl:w-full input  border text-gray-700 block mt-4"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isDisabled}
                      required
                      autoComplete="on"
                    />
                    {show ? (
                      <Icons.EyeOff
                        className="text-gray-500 mt-4 mx-2 cursor-pointer"
                        onClick={() => onShowPassword()}
                      />
                    ) : (
                      <Icons.Eye
                        className="text-gray-500 mt-4 mx-2 cursor-pointer"
                        onClick={() => onShowPassword()}
                      />
                    )}
                  </div>
                </div>
                <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
                  <div className="flex items-h-screen xl:h-autocenter mr-auto"></div>
                  <Link to="mot-de-passe-oublie">Mot de passe oublié?</Link>
                </div>
                <div className="intro-x mt-5 xl:mt-8 text-center  flex">
                  <button
                    type="submit"
                    className="button button--lg w-full xl:w-full text-white bg-theme-11 align-top"
                  >
                    {isDisabled ? "Chargement" : "Connexion"}
                  </button>
                </div>
                <div className="intro-x mt-10 text-gray-700 dark:text-gray-600 text-center ">
                  En vous connectant vous acceptez nos
                  <br />
                  <Link className="text-theme-1 " to="">
                    Conditions d'uilisations
                  </Link>
                  &
                  <Link className="text-theme-1 dark:text-theme-10" to="">
                    Politique de confidentialité
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginView;
