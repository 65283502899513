import React from "react";
import { Routes } from "react-router-dom";
import LayoutAdmin from "../layouts/LayoutAdmin";
// import LoginView from "../views/auth/LoginView";
// import ResetPassword from "../views/auth/ResetPassword";
// import ForgetPasswordView from "../views/auth/ForgetPasswordView";
// import DashboardView from "../views/DashboardView";

export function MainRoutes(props) {
  return (
    <LayoutAdmin>
      <Routes>
        {/* <Route path="Client" element={<DashboardView />} /> */}
      </Routes>
    </LayoutAdmin>
  );
}
