import "../assets/css/app.css";

// import { useNavigate } from 'react-router-dom';
import { MobileMenu } from "../components/MobileMenu";
import { SideNav } from "../components/SideNav";
import { TopBar } from "../components/TopBar";
import Client from "../components/Client";
import Achat from "../components/Achat";
import Recherche from "../components/Recherche";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Utils } from "../utils";
export default function MainLayout(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!Utils.Auth.isLoggedIn()) navigate("/");
  });
  const location = useLocation().pathname.replaceAll("/", "");
  return (
    <>
      <MobileMenu />
      <div className="flex">
        <SideNav />
        <div className="content">
          <TopBar />

          {(() => {
            switch (location) {
              case "Client":
                return <Client />;
              case "Achat":
                return <Achat />;
              case "Recherche":
                return <Recherche />;
              default:
                return (
                  <div className="text-theme-11 text-3xl">
                    Indisponible pour le moment
                  </div>
                );
            }
          })()}

          {props.children}
        </div>
      </div>
    </>
  );
}
