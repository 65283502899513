import  { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import logoImg from "../../assets/images/Logo_DATA_GREFFE.png";
import { AuthService } from "../../services/AuthService";
// import { Utils } from "../../utils";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const abortController = useMemo(() => new AbortController(), []);

  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [, setErrorMessages] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  // const navigate = useNavigate();
  const { uid, token } = useParams();

  // useEffect(() => {
  //   if (!Utils.Auth.isLoggedIn()) navigate("/");
  // });

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    try {
      if (password && password_confirmation) {
        const payload = {
          password,
          uid: uid,
          token: token,
        };
        await AuthService.password(
          JSON.stringify(payload),
          abortController.signal
        );

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Votre a été réinitialisé avec success",
          showConfirmButton: false,
          timer: 2000,
        }).then((result) => {
          window.location.replace("/");
        });
      }
    } catch (error) {
      setErrorMessages();
    } finally {
      setIsDisabled(false);
    }
  };

  const checkField = (e) => {
    if (!e.target.value || e.target.value === "") {
      setMessage("Veuillez remplir le champ!");
      setError(true);
    } else {
      setMessage("");
      setError(false);
    }
  };
  return (
    <form name="loginForm" id="loginForm" onSubmit={handlePasswordSubmit}>
      <div className="login">
        <div className="container  ">
          <div className="block xl:grid grid-cols-2 gap-4">
            <div className="hidden xl:flex flex-col min-h-screen">
              <div className="my-auto">
                <Link to="" className="-intro-x flex mx-20 items-center">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    className="w-80 h-64"
                    src={logoImg}
                  />
                </Link>
                <div className="-intro-x text-white font-medium text-xl leading-tight">
                  Accédez a toutes les informations légales,
                  <br /> juridiques et financières sur les entreprises de votre
                  choix.
                </div>
                <div className="-intro-x mt-8 text-md text-white dark:text-gray-500">
                  Gérez tous vos documents achetés en un seul endroit.
                </div>
              </div>
            </div>

            <div className="h-screen xl:h-auto flex ">
              <div className="my-auto mx-auto  bg-white xl:bg-transparent sm:bg-white px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                <h2 className="intro-x font-bold text-theme-11 text-2xl xl:text-3xl text-center ">
                  Réinitialiser votre mot de passe
                </h2>

                <div className="intro-x mt-8">
                  <input
                    type="password"
                    className="intro-x w-full xl:w-full input text-gray-600 input--lg  border border-gray-300 block"
                    name="password"
                    placeholder="Nouveau mot de passe"
                    value={password}
                    disabled={isDisabled}
                    onChange={(e) => {
                      checkField(e);
                      setPassword(e.target.value);
                    }}
                    required
                  />
                  {error !== null ? (
                    <h2 style={{ color: "red" }}>{message}</h2>
                  ) : (
                    ""
                  )}
                </div>
                <div className="intro-x mt-8">
                  <input
                    type="password"
                    name="password_confirmation"
                    className="intro-x w-full xl:w-full input input--lg text-gray-600 border border-gray-300 block"
                    placeholder="Confirmer votre mot de passe"
                    value={password_confirmation}
                    disabled={isDisabled}
                    onChange={(e) => setPassword_confirmation(e.target.value)}
                    required
                  />
                  {password_confirmation &&
                  password !== password_confirmation ? (
                    <h2 style={{ color: "red" }}>
                      Mot de passe non identique!
                    </h2>
                  ) : (
                    ""
                  )}
                </div>

                <div className="intro-x mt-5 xl:mt-8 text-center ">
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className="button button--lg w-full xl:w-full text-white bg-theme-11 align-top"
                  >
                    {isDisabled ? "Chargement" : "Réinitialiser"}
                  </button>
                </div>

                <div className="intro-x mt-5 xl:mt-5 text-center ">
                  <Link to="/" className="-intro-x flex items-center pt-5">
                    <button className="button button--lg w-full xl:w-full text-gray-600  bg-theme-5 align-top">
                      Se connecter
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
