import { Link, NavLink, useLocation } from "react-router-dom";
import logoImg from "../assets/images/Logo_DATA_GREFFE.png";
import * as Icons from "react-feather";
import { Auth } from "../utils/Auth";
// import { useState } from "react";

export function SideNav() {
  const { pathname } = useLocation();
  const {role} = Auth.getUser();
//   console.log("dataUser", dataUser);
// const [stateRole, setStateRole] =  useState(false)

// if (role ==="administrateur_TCA") {
//     setStateRole(!stateRole)
// }
  const activeClassName = "side-menu--active";
  const dynamicClassName = ({ isActive }) => {
    return isActive ? `side-menu ${activeClassName}` : "side-menu";
  };

  return (
    <nav className="side-nav">
      <Link
        to="/"
        className="intro-x t  text-center"
        style={{ position: "relative", zIndex: 3 }}
      >
        <img
          alt="Midone Tailwind HTML Admin Template"
          className="w-20 mx-auto mt-3"
          src={logoImg}
        />
      </Link>
      <div className="side-nav__devider my-6"></div>
      <ul>
        <li className={role ==="administrateur_TCA"? 'hidden':''}>
          <Link
            to="/Client"
            className={`side-menu ${
              pathname === "/Client" ? activeClassName : ""
            }`}
          >
            <div className="side-menu__icon">
              <Icons.Users />{" "}
            </div>
            <div className="side-menu__title"> Clients </div>
          </Link>
        </li>
        <li>
          <NavLink to="/Achat" className={dynamicClassName}>
            <div className="side-menu__icon">
              <Icons.ShoppingCart />
            </div>
            <div className="side-menu__title">Achats</div>
          </NavLink>
        </li>
        <li className={role ==="administrateur_TCA"? 'hidden':''}>
          <NavLink to="/Recherche" className={dynamicClassName}>
            <div className="side-menu__icon">
              <Icons.Search />
            </div>
            <div className="side-menu__title"> Recherches </div>
          </NavLink>
        </li>
        {/* <li>
                    <NavLink to="/Aide" className={dynamicClassName}>
                        <div className="side-menu__icon">
                            <Icons.HelpCircle />
                        </div>
                        <div className="side-menu__title"> Aides </div>
                    </NavLink>
                </li> */}
        <li className="side-nav__devider my-6"></li>
      </ul>
    </nav>
  );
}
