import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../utils";
// import { Services } from '../services';
import * as Icons from "react-feather";
import { AuthService } from "../services/AuthService";

export function TopBar() {
  const location = useLocation().pathname.replaceAll("/", "");
  const navigate = useNavigate();

  return (
    <div className="top-bar" style={{ position: "relative", zIndex: 50 }}>
      <div className="intro-x breadcrumb mr-auto hidden sm:flex">
        <div className="breadcrumb text-2xl ">{location}s</div>
      </div>
      <div className="intro-x  mr-auto sm:mr-6 text-gray-800 italic">
      {Utils.Auth.getUser()?.username ?? "Utilisateur"}
      </div>
      
      <div className="intro-x dropdown w-8 h-8">
        <div
          className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden 
                shadow-xl bg-theme-8 image-fit zoom-in"
          onClick={() => Utils.Dom.toggleVisibility("#profile-dropdown")}
        >
          <Icons.Settings color="green" className="w-5 mt-1 mx-auto  " />
        </div>
        <div className="dropdown-box w-56" id="profile-dropdown">
          <div className="dropdown-box__content box bg-theme-38 dark:bg-dark-6 text-white">
            <div className="p-4 border-b border-theme-5 dark:border-dark-3">
              <div className="font-medium">
                {Utils.Auth.getUser()?.username ?? "Utilisateur"}
              </div>
              <div className="text-xs text-theme-41 dark:text-gray-600">
                En ligne
              </div>
            </div>
            <div className="p-2">
              <Link
                to="/profil"
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out hover:bg-theme-8  rounded-md"
              >
                <Icons.User className="w-4 h-4 mr-2" />
                Profil
              </Link>
              <Link
                to="/nouveau-mot-de-passe"
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out hover:bg-theme-8  rounded-md"
              >
                <Icons.Lock className="w-4 h-4 mr-2" />
                Mot de passe
              </Link>
            </div>
            <div className="p-2 border-t border-theme-5 dark:border-dark-3">
              <button
                className="flex items-center block p-2 transition duration-300 
                            ease-in-out bg-theme-1 hover:bg-theme-8  rounded-md"
                style={{ width: "100%" }}
                onClick={() => AuthService.logout(navigate)}
              >
                {/* onClick={() => Services.AuthService.logout(navigate)}   */}
                <Icons.LogOut className="w-4 h-4 mr-2" />
                Deconnexion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
  );
}
